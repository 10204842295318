var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": "",
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              "empty-filtered-text": "Nenhum registro encontrado",
              "empty-text": "Nenhum registro encontrado",
            },
            scopedSlots: _vm._u([
              {
                key: "cell(name)",
                fn: function (ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(item.name) + " ")]
                },
              },
              {
                key: "cell(user)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.user
                      ? _c("span", [_vm._v(_vm._s(item.user.title))])
                      : _c("span", [_vm._v("Nenhum")]),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (data) {
                  return [
                    _c("feather-icon", {
                      staticClass: "cursor-pointer cursor",
                      attrs: { icon: "EditIcon", size: "16" },
                      on: {
                        click: function ($event) {
                          return _vm.modalEdit(data.item)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-create-order-purchase-steps",
                "modal-class": "modal-create",
                title: _vm.titleModal,
                centered: "",
                "hide-footer": "",
              },
            },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submitUpdate.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Responsável "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "v-select",
                            {
                              class: {
                                "is-invalid": _vm.$v.item.responsable.$error,
                              },
                              attrs: {
                                label: "title",
                                "item-text": "title",
                                "item-value": "code",
                                placeholder: "Digite o nome",
                                options: _vm.optionsResponsables,
                              },
                              on: { search: _vm.fetchUsers },
                              model: {
                                value: _vm.item.responsable,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item, "responsable", $$v)
                                },
                                expression: "item.responsable",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  slot: "no-options",
                                },
                                [_vm._v(" Nenhum registro encontrado ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-row justify-content-end modal-footer-custom",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-7 col-12" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light float-left",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.hide(
                                    "modal-create-order-purchase-steps"
                                  )
                                },
                              },
                            },
                            [_vm._v(" Fechar ")]
                          ),
                          _c("ButtonsActionsFooter", {
                            attrs: {
                              variant: "success",
                              submited: _vm.submited,
                              text: _vm.textSubmit,
                              subtext: "Aguarde...",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }