<template>
    <div>
      <b-card no-body class="mb-0">  
        <b-table
          sticky-header
          class="position-relative"
          :items="dataItems"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-filtered-text="Nenhum registro encontrado"
          empty-text="Nenhum registro encontrado"
        >
          <template #cell(name)="{ item }">
            {{ item.name }}
          </template>

          <template #cell(user)="{ item }">
           <span v-if="item.user">{{ item.user.title }}</span>
           <span v-else>Nenhum</span>
          </template>
  
          <template #cell(actions)="data">
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer cursor"
              size="16"
              @click="modalEdit(data.item)"
            />
          </template>
        </b-table>
  
        <b-modal
          id="modal-create-order-purchase-steps"
          modal-class="modal-create"
          :title="titleModal"
          centered
          hide-footer
        >
          <b-form @submit.prevent="submitUpdate">
            <div class="form-row">
              <div class="col-12">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Responsável
                </label>
                <div class="form-group">
                  <v-select
                    label="title"
                    item-text="title"
                    item-value="code"
                    v-model="item.responsable"
                    placeholder="Digite o nome"
                    :options="optionsResponsables"
                    @search="fetchUsers"
                    :class="{ 'is-invalid': $v.item.responsable.$error }"
                  >
                    <span
                      slot="no-options"
                    >
                      Nenhum registro encontrado
                    </span>
                  </v-select>
                </div>
              </div>
            </div>
            <div class="form-row justify-content-end modal-footer-custom">
              <div class="col-md-7 col-12">
                <button
                  type="button"
                  @click="
                    $bvModal.hide('modal-create-order-purchase-steps')
                  "
                  class="btn btn-light float-left"
                >
                  Fechar
                </button>
                <ButtonsActionsFooter
                  variant="success"
                  :submited="submited"
                  :text="textSubmit"
                  subtext="Aguarde..."
                />
              </div>
            </div>
          </b-form>
        </b-modal>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    BSpinner,
    BModal,
    BFormGroup,
    BTooltip,
    BForm,
    BCardText,
    BFormRow,
    BContainer,
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  import vSelect from "vue-select";
  import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
  import { required } from "vuelidate/lib/validators";
  import CustomPaginateTable from "@/views/components/custom/PaginationTable";
  
  export default {
    components: {
      BCard,
      CustomPaginateTable,
      BModal,
      BFormGroup,
      BRow,
      BCol,
      BFormInput,
      BTable,
      BButton,
      BPagination,
      vSelect,
      BTooltip,
      BForm,
      BCardText,
      BFormRow,
      BContainer,
      ButtonsActionsFooter,
      BSpinner,
    },
    beforeDestroy() {
      this.$root.$off("paginate:update");
    },
    data() {
      return {
        uuid: null,
        textSubmit: "",
        submited: false,
        filter: null,
        filterOn: [],
        totalRows: 0,
        rowsTable: 0,
        optionsResponsables: [],
        tableColumns: [
        {
            key: "name",
            label: "Nome",
          },
          {
            key: "user",
            label: "Responsável",
            class: "text-center",
          },
          {
            key: "actions",
            label: "",
            class: "text-center",
            thStyle: "width: 80px",
          },
        ],
        dataItems: [],
        titleModal: "",
        item: {
          responsable: "",
        },
      };
    },
    validations: {
      item: {
        responsable: {
          required,
        },
      },
    },
    mounted() {
      this.getData();
  
      this.$root.$on("open:modal-create-order-purchase-steps", () => {
        this.clearForm();
        this.textSubmit = "Cadastrar";
        this.titleModal = "Cadastrar Categoria";
        this.$bvModal.show("modal-create-order-purchase-steps");
      });
    },
    beforeDestroy() {
      this.$root.$off("open:modal-create-order-purchase-steps");
    },
    methods: {
      async fetchUsers(term) {
        if (term.length > 2) {
          this.$store.dispatch("User/search", term).then((res) => {
            this.optionsResponsables = res
          });
        }
      },
      modalEdit(element) {
        this.textSubmit = "Salvar";
        this.titleModal = "Editar";
        this.clearForm();
  
        this.item = element;
        this.$bvModal.show("modal-create-order-purchase-steps");
      },
      submitUpdate() {
        this.$v.item.$touch();
        this.submited = true;
  
        if (!this.$v.item.$error) {
          this.$store
            .dispatch("OrderPurchase/updateStep", {
              uuid: this.item.uuid,
              data: this.item,
            })
            .then(() => {
              this.getData();
              this.notifyDefault("success");
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-order-purchase-steps");
                this.clearForm();
              });
            })
            .catch(() => {
              this.submited = false;
            })
            .finally(() => {
              this.submited = false;
            });
        } else {
          this.submited = false;
          this.notifyDefault("error", "Verifique os campos obrigatórios");
        }
      },
      clearForm() {
        this.item = {
          user: "",
        };
      },
      async getData() {
        this.$store
          .dispatch("OrderPurchase/allSteps")
          .then((res) => {
            this.dataItems = res.data;           
          });
      },
      updatePage() {
        this.getData();
      },
    },
    directives: {
      Ripple,
    },
  };
  </script>
  